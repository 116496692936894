.chat-box {
  position: relative;
  background-color: white;
  border-radius: 8px;
  z-index: 10;
}

.chat-box::before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 0;
  width: 0;
  height: 0;
  border-right: 20px solid white;
  border-top: 20px solid transparent;
}

.user-gradient {
  background: linear-gradient(
    260.18deg,
    rgba(207, 97, 225, 0.1) -26.71%,
    rgba(0, 35, 239, 0.1) 100%
  );
  box-shadow: 0px 20px 40px rgba(168, 173, 197, 0.07);
  border-radius: 6px;
}

.user-gradient-text {
  background: linear-gradient(260.18deg, #cf61e1 -26.71%, #0023ef 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.error-gradient {
  background: linear-gradient(
    260.18deg,
    rgba(255, 0, 0, 0.1) -26.71%,
    rgba(128, 0, 0, 0.1) 100%
  );
  box-shadow: 0px 20px 40px rgba(255, 0, 0, 0.07);
  border-radius: 6px;
}

.error-gradient-text {
  background: linear-gradient(260.18deg, #ff0000 -26.71%, #800000 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

/* chatbox.css */
.chat-container::-webkit-scrollbar {
  width: 12px;
}

.chat-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.chat-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}
