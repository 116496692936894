.slick-dots {
  top: 130% !important;
}
.slider-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

@media (min-width: 640px) {
  .slider-item {
    flex-direction: row !important;
    align-items: center !important;
  }
}
