@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.cdnfonts.com/css/tt-commons");

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
  /* overflow-y: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-custom {
  height: calc(100dvh - 238px);
}

.h-custom-bar {
  height: calc(100dvh - 88px);
}

.w-custom {
  width: calc(100vw - 450px);
}

.w-custom-input {
  width: 100%;
}

@media (min-width: 640px) {
  .w-custom-input {
    width: 65%;
  }
}

@media (min-width: 1280px) {
  .w-custom-input {
    width: calc(100vw - 450px);
  }
}

.dim-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 20;
}

.dimmed {
  position: relative;
}
