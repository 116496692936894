@media (min-width: 1280px) {
  .custom-width {
    width: calc(100% - 450px);
  }
}

.dim-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

.dimmed {
  position: relative;
}
