/* styles.css or another CSS file */
@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  background: linear-gradient(260deg, #cf61e1 -26.71%, #0023ef 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

/* Ensure your component or a parent component has a unique class */
.my-steps-component .ant-steps-item-process .ant-steps-item-icon {
  background-color: #0023ef !important;
  border-color: #0023ef !important;
  font-size: 18px;
}

.ant-steps-item-finish .ant-steps-item-title:after {
  background: linear-gradient(to right, #0023ef 0%, #cf61e1 5%) !important;
  background-size: 100% 100%; /* This will stretch the gradient over the full width and height */
  /* You might need to adjust width and height here */
  width: 100vw; /* Adjust this as needed */
  height: 2px; /* Adjust this if needed */
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: linear-gradient(110deg, #0023ef, #cf61e1) !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-steps-item-finish .ant-steps-item-icon .anticon-check svg {
  width: 20px; /* adjust as needed */
  height: 20px; /* adjust as needed */
  transform: translateX(-45%) translateY(-5%);
  position: relative;
  top: 50%;
  left: 50%;
}

.ant-steps-item-finish .ant-steps-item-icon .anticon-check svg path {
  fill: #fff !important;
  stroke: #fff; /* adds stroke color */
}

.enlarged {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.enlarged-lottie {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
