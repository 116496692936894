ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

ol,
ul {
  margin-left: 1rem;
}

.word-break {
  word-break: break-all;
}

.unselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
