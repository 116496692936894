.simple-shadow-bottom {
  box-shadow: 0 8px 10px -6px rgba(0, 0, 0, 0.2);
}

.simple-shadow-top {
  box-shadow: 0 -8px 10px -6px rgba(0, 0, 0, 0.2);
}

.simple-shadow-bottom-left {
  box-shadow: -8px 8px 10px -6px rgba(0, 0, 0, 0.2);
}

.h-custom {
  min-height: calc(100dvh - 88px);
}
